import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'
import { isEmpty, chain, find, pull, isString, omit } from 'lodash'

import { useAuth } from '@praxis/component-auth'
import {
  Button,
  Grid,
  Layout,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterIcon } from '@enterprise-ui/icons'

import { ProgressOverlay, SavedSearchMenu } from '@dlm/common'
import '@dlm/common/dist/esm/css/styles.css'
import '../../stylesheets/customStyles.scss'

import DepartureLogTable from './components/DepartureLogTable'
import SearchFilterDrawer from './components/SearchFilterDrawer'
import SearchFilterChips from './components/SearchFilterChips'

import departureLogService from './services/departureLogService'
import savedSearchService from './services/savedSearchService'

import apiConfig from '../../config/apiConfig'

import {
  DEFAULT_SELECTED_FILTERS,
  defaultLoadSearchFilters,
} from './constants/searchFilterConstants'
import { DEPARTURE_LOG_FILTER_DATA_CRITERIA } from './constants/criteriaConstants'

const DEFAULT_SORT_COLUMN = 'departure_log_status'

const DepartureLog = () => {
  const auth = useAuth()
  const { session } = auth
  const user = session?.userInfo
  const accessToken = session?.accessToken

  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const makeToast = ToastProvider.useToaster()
  const [loadData, setLoadData] = useState([])
  const [loadCount, setLoadCount] = useState(0)
  const [inProgress, setInProgress] = useState(false)
  const [loadStatusOptions, setLoadStatusOptions] = useState([])
  const [alertStatusOptions, setAlertStatusOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [nodeInfo, setNodeInfo] = useState([])
  const [savedSearchData, setSavedSearchData] = useState('')

  const [loadSearchFilters, setLoadSearchFilters] = useState({
    page: 1,
    per_page: 100,
    criteria_id: DEPARTURE_LOG_FILTER_DATA_CRITERIA,
    user_timezone_offset_hours: 5,
    sort_by: DEFAULT_SORT_COLUMN,
    is_descending_sort: true,
    selected_filters: [...DEFAULT_SELECTED_FILTERS],
    ...defaultLoadSearchFilters(),
  })
  const onPaginationChange = (pageNum, pageSize) => {
    setLoadSearchFilters((loadSearchFilters) => ({
      ...loadSearchFilters,
      page: pageNum,
      per_page: pageSize,
    }))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchLoad = (searchFilters) => {
    onSearchFilterChange({
      ...searchFilters,
      critical_departure_start_time:
        loadSearchFilters.critical_departure_start_time,
      critical_departure_end_time:
        loadSearchFilters.critical_departure_end_time,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchFilterChange = (newFilters) => {
    const updatedSelectedFilters = chain(newFilters)
      .pick(Object.keys(defaultLoadSearchFilters()))
      .omitBy(isEmpty)
      .keys()
      .value()
    // Need to re-instate pagination and sort properties as filter drawer strips them
    setLoadSearchFilters({
      ...newFilters,
      page: 1,
      criteria_id: DEPARTURE_LOG_FILTER_DATA_CRITERIA,
      selected_filters: updatedSelectedFilters,
      /*origin_ids: newFilters.origin_ids.map((value) => value.value || value.id),
      destination_ids: newFilters.destination_ids.map(
        (value) => value.value || value.id,
      ),*/
      per_page: loadSearchFilters.per_page,
      sort_by: loadSearchFilters.sort_by,
      is_descending_sort: loadSearchFilters.is_descending_sort,
    })
  }

  const onSearchFilterDelete = (filter) => {
    setLoadSearchFilters({
      ...loadSearchFilters,
      selected_filters: pull(loadSearchFilters.selected_filters, filter),
      [filter]: defaultLoadSearchFilters(false)[filter],
    })
  }

  const onSearchFilterReset = () => {
    setLoadSearchFilters({
      page: 1,
      per_page: loadSearchFilters.per_page,
      sort_by: loadSearchFilters.sort_by,
      criteria_id: DEPARTURE_LOG_FILTER_DATA_CRITERIA,
      is_descending_sort: loadSearchFilters.is_descending_sort,
      selected_filters: [...DEFAULT_SELECTED_FILTERS],
      ...defaultLoadSearchFilters(),
    })
  }

  const onSortChange = (direction, column) => {
    const sortBy = direction === null ? DEFAULT_SORT_COLUMN : column
    const isDescendingSort = direction === null ? true : direction === 'dsc'

    // Only change filters if there is actual change to sorting. Avoids unnecessary renders
    if (
      sortBy !== loadSearchFilters.sort_by ||
      isDescendingSort !== loadSearchFilters.is_descending_sort
    ) {
      setLoadSearchFilters((loadSearchFilters) => ({
        ...loadSearchFilters,
        page: 1,
        per_page: loadSearchFilters.per_page,
        sort_by: sortBy,
        is_descending_sort: isDescendingSort,
      }))
    }
  }

  const markDefault = (criteriaId) => {
    savedSearchService
      .setDefaultSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully marked default',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error marking default',
        })
      })
  }

  const onSaveSearch = (newFilters) => {
    savedSearchService
      .saveSearch(newFilters)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully saved search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error saving search',
        })
      })
  }

  const deleteSavedSearch = (criteriaId) => {
    savedSearchService
      .deleteSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully deleted search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error deleting search',
        })
      })
  }

  const getLoads = useCallback(() => {
    if (savedSearchRef.current) {
      setInProgress(true)
      departureLogService
        .getLoads(omit(loadSearchFilters, 'selected_filters'))
        .then((resp) => {
          setLoadCount(resp.row_count)
          setLoadData(resp.loads)
        })
        .catch(() => {
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error fetching departure logs',
          })
        })
        .finally(() => {
          // TODO - Remove timeout after https://jira.target.com/browse/EUI-1740 is implemented
          setTimeout(() => setInProgress(false), 500)
        })
    }
  }, [makeToast, loadSearchFilters])

  useEffect(() => {
    getLoads()
  }, [getLoads])

  // On Load setup
  useEffect(() => {
    // Fetch saved searches
    getSavedSearches()

    // Fetch metadata
    departureLogService
      .getCriteria(DEPARTURE_LOG_FILTER_DATA_CRITERIA)
      .then((resp) => {
        const loadPredicate = resp.predicate_json.load_predicate
        setLoadStatusOptions(
          loadPredicate.departure_log_status_list.map((status) => {
            return { label: status.replaceAll('_', ' '), value: status }
          }),
        )
        setAlertStatusOptions(
          loadPredicate.alert_status_list.map((alertStatus) => {
            return {
              label: alertStatus.replaceAll('_', ' '),
              value: alertStatus,
            }
          }),
        )
        setCategoryOptions(loadPredicate.category_list)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching criteria details',
        })
      })

    // Fetch location data
    departureLogService
      .getLocation(user)
      .then((resp) => {
        const nodeInfoData = resp.map((node) => ({
          label: `${node.node_id} - ${node.display_name}`,
          value: node.node_id,
          id: node.node_id,
        }))
        setNodeInfo(nodeInfoData)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching location data',
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Perform default saved Search (If any) on load
  const savedSearchRef = useRef(null)
  useEffect(() => {
    if (!savedSearchRef.current && !isEmpty(savedSearchData)) {
      // Saved searches loaded. We can now fetch default saved search or default search criteria

      const defaultSavedSearch =
        !isString(savedSearchData) &&
        find(savedSearchData, { default_search: true })

      if (defaultSavedSearch) {
        onSearchLoad(defaultSavedSearch.filters)
      } else {
        onSearchFilterChange(defaultLoadSearchFilters())
      }
    }

    if (!isEmpty(savedSearchData)) {
      savedSearchRef.current = savedSearchData
    }
  }, [savedSearchData, onSearchFilterChange, onSearchLoad])

  const exportCSV = (type) => {
    if (loadCount <= 500) {
      departureLogService
        .getCSVReport(loadSearchFilters, user, type)
        .then(() => {
          makeToast({
            type: 'success',
            heading: 'Success',
            message: `Successfully sent CSV report to email: ${user.email}`,
          })
        })
        .catch((error) => {
          console.error(error)
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error creating CSV report',
          })
        })
    } else {
      makeToast({
        type: 'error',
        heading: `Load count from query is too large: ${loadCount}, please narrow your search filters`,
        message: 'Error creating CSV report',
      })
    }
  }

  const getSavedSearches = () => {
    savedSearchService.getSavedSearches().then((mappedResponse) => {
      setSavedSearchData(mappedResponse)
    })
  }

  const saveUpdates = (updates) => {
    departureLogService
      .updateDepartureLog(updates, user)
      .then(() => {
        getLoads()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: `Successfully updated departure log`,
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error updating departure log',
        })
      })
  }

  return (
    <>
      <ProgressOverlay inProgress={inProgress} />
      <SearchFilterDrawer
        isVisible={filtersVisible}
        onRequestClose={() => {
          setFiltersVisible(false)
        }}
        onChange={onSearchFilterChange}
        loadStatusOptions={loadStatusOptions}
        alertStatusOptions={alertStatusOptions}
        loadCategories={categoryOptions}
        nodeInfo={nodeInfo}
        onSaveSearch={onSaveSearch}
        givenValues={loadSearchFilters}
        savedSearches={savedSearchData}
        loadSearchFilters={loadSearchFilters}
      />
      <Layout.Body data-testid="departureLog" includeRail>
        <Grid.Container justify="flex-end" style={{ marginBottom: 10 }}>
          <Grid.Item>
            <SavedSearchMenu
              mappedSavedSearchData={savedSearchData}
              onLoadSearch={onSearchLoad}
              onToggleDefaultSearch={markDefault}
              onDelete={deleteSavedSearch}
            />
          </Grid.Item>
          <Grid.Item>
            <Button
              onClick={() => {
                setFiltersVisible(true)
              }}
              aria-label="Show Search Filters"
              data-testid="filters-button"
            >
              <EnterpriseIcon
                icon={FilterIcon}
                size="sm"
                style={{ marginRight: 5 }}
              />
              Show Filters
            </Button>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item xs={12}>
            <DepartureLogTable
              aria-label="Departure Log"
              loads={loadData}
              loadCount={loadCount}
              pageNum={loadSearchFilters.page}
              pageSize={loadSearchFilters.per_page}
              onExport={exportCSV}
              onSave={saveUpdates}
              onRefresh={getLoads}
              onPaginationChange={onPaginationChange}
              onSortChange={onSortChange}
              subHeader={
                <SearchFilterChips
                  searchFilters={loadSearchFilters}
                  onDelete={onSearchFilterDelete}
                  onReset={onSearchFilterReset}
                />
              }
            />
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default DepartureLog
