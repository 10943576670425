import { formatInTimeZone } from 'date-fns-tz'

const date_options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)
  if (isValidDate(date)) {
    return date.toLocaleString('en-US', date_options).split('T')[0]
  }
  return ''
}
export const formatDateTime = (dateString, timeZoneOffsetFromUTC) => {
  return isValidDate(new Date(dateString))
    ? formatInTimeZone(dateString, timeZoneOffsetFromUTC, "yyyy-MM-dd'T'HH:mm")
    : ''
}

export const formatSearchFilterDate = (dateString) => {
  const date = new Date(dateString)
  if (isValidDate(date)) {
    return date.toISOString().split('T')[0]
  }
  return ''
}

export const convertToLocationZone = (dateString, timeZoneOffsetFromUTC) => {
  return formatDate(formatDateTime(dateString, timeZoneOffsetFromUTC))
}
