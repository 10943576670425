import { Chip } from '@enterprise-ui/canvas-ui-react'

export const departureStatusDisplay = {
  'LOAD NOT READY': (
    <Chip size="dense" color="error">
      Load Not Ready
    </Chip>
  ),
  'PENDING CARRIER PICKUP': (
    <Chip size="dense" className="blue-chip">
      Pending Carrier Pickup
    </Chip>
  ),
  DEPARTED: (
    <Chip size="dense" color="success">
      Departed
    </Chip>
  ),
  CANCELED: <Chip size="dense">Canceled</Chip>,
}

export const unknownStatus = <Chip size="dense">Unknown</Chip>
