import { useState } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'

import { MainRouter } from './MainRouter'
import { Header } from './Header'
import { SideNavigation } from './SideNavigation'

export const Main = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const openSideNav = () => {
    setIsSideNavOpen(true)
  }
  const closeSideNav = () => {
    setIsSideNavOpen(false)
  }

  return (
    <Layout
      theme="target" // configure theme here
      darkMode="system" // configure dark mode here
      data-testid="mainAuthenticatedLayout"
      fullWidth
    >
      <Header onSideNavOpen={openSideNav} />
      <SideNavigation isVisible={isSideNavOpen} onRequestClose={closeSideNav} />
      <Layout.Body includeRail className="topPage-layout-body">
        <MainRouter />
      </Layout.Body>
    </Layout>
  )
}
