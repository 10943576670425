import { Link } from 'react-router-dom'
import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Heading,
  Layout,
  TargetLogo,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MenuIcon } from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'

const getInitials = (userInfo) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`

export const Header = ({ onSideNavOpen }) => {
  const auth = useAuth()
  const { logout, session } = auth
  return (
    <>
      <Layout.Header className="topPage-layout-header">
        <Grid.Container spacing="dense">
          <Grid.Item>
            <Button
              className="C-MenuButton header-icon-button"
              onClick={onSideNavOpen}
              iconOnly
              data-testid="headerMenuButton"
              aria-label="open side nav menu"
            >
              <EnterpriseIcon icon={MenuIcon} size="lg" />
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Link to="/">
              <TargetLogo size="expanded" color="white" />
            </Link>
          </Grid.Item>
          <Grid.Item>
            <Link to="/">
              <Heading size={4} className="white-header">
                Departure Log
              </Heading>
            </Link>
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
      <Layout.GlobalActions>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Dropdown size="dense" location="bottom-right">
              <Button
                aria-label="Click to open dropdown menu"
                iconOnly
                className="praxcss-avatarButton"
                data-testid="headerGlobalActionsButton"
              >
                <Avatar
                  aria-label={`Avatar showing the initials for ${session.userInfo.fullName}`}
                >
                  {getInitials(session.userInfo)}
                </Avatar>
              </Button>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={logout}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  )
}
