import { Button, Chip, Grid } from '@enterprise-ui/canvas-ui-react'
import {
  DEFAULT_SELECTED_FILTERS,
  FILTER_LABELS,
} from '../constants/searchFilterConstants'
import { isEqual } from 'lodash'

const SearchFilterChips = ({ searchFilters, onDelete, onReset }) => {
  return (
    <Grid.Container align="center">
      <Grid.Item>
        <Button
          data-testid="sf_reset_button"
          onClick={onReset}
          type="primary"
          disabled={isEqual(
            searchFilters.selected_filters,
            DEFAULT_SELECTED_FILTERS,
          )}
        >
          Reset Filters
        </Button>
      </Grid.Item>
      <Grid.Item>
        {searchFilters.selected_filters.map((value) => (
          <Chip
            key={value}
            className="hc-ma-none"
            data-testid={`filter_chip_${value}`}
            onRequestDelete={() => {
              onDelete(value)
            }}
          >
            {FILTER_LABELS[value]}
          </Chip>
        ))}
      </Grid.Item>
    </Grid.Container>
  )
}

export default SearchFilterChips
