import qsUtil from '../../../util/queryStringUtil'
import axios from 'axios'
import apiConfig from '../../../config/apiConfig'
import { formatSearchFilterDate } from '../../../util/dateUtil'

async function getLoads(params) {
  const mappedParams = {
    ...params,
    origin_ids: params.origin_ids
      ? params.origin_ids.map((value) => value.value || value.id)
      : [],
    destination_ids: params.destination_ids
      ? params.destination_ids.map((value) => value.value || value.id)
      : [],
    categories: params.category_list
      ? params.category_list.map((value) => value.type)
      : [],
    subcategories: params.category_list
      ? params.category_list.flatMap((value) => value.sub_types)
      : [],
    category_list: [],
    start_from_date: formatSearchFilterDate(params.start_from_date),
    start_to_date: formatSearchFilterDate(params.start_to_date),
  }
  const { data } = await axios.get(`${apiConfig.api.edge}/loads`, {
    params: qsUtil.createParams(mappedParams),
  })
  return data
}

async function getCriteria(criteriaId) {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/criteria/${criteriaId}`,
  )
  return data
}

async function getLocation(user) {
  const { data } = await axios.get(`${apiConfig.api.edge}/dcs`)
  return data
}

async function updateDepartureLog(recordUpdates, user) {
  const payload = formatDepartureLogUpdatesDTO(recordUpdates, user)
  const { data } = await axios.put(
    `${apiConfig.api.edge}/departure_log_updates`,
    payload,
  )
  return data
}

const formatDepartureLogUpdatesDTO = (recordUpdates, user) => {
  return {
    update_by: user.email,
    departure_log_updates: Object.keys(recordUpdates).map((loadId) => ({
      load_id: loadId,
      comments: recordUpdates[loadId].comments,
    })),
  }
}

async function getCSVReport(loadSearchFilters, user, type) {
  const params = formatToCSVReportParams(loadSearchFilters, user, type)
  const { data } = await axios.get(`${apiConfig.api.edge}/loads/report`, {
    params: qsUtil.createParams(params),
  })
  return data
}

export function formatToCSVReportParams(loadSearchFilters, user, type) {
  return {
    isDescendingSort: loadSearchFilters.is_descending_sort,
    load_id_list: loadSearchFilters.load_ids,
    departure_log_status_list: loadSearchFilters.departure_log_statuses,
    // TODO : revert logic for autocomplete fields when we correct the component's value
    origin_ids: loadSearchFilters.origin_ids
      ? loadSearchFilters.origin_ids.map((value) => value.value || value.id)
      : [],
    destination_ids: loadSearchFilters.destination_ids
      ? loadSearchFilters.destination_ids.map(
          (value) => value.value || value.id,
        )
      : [],
    start_from_date: formatSearchFilterDate(loadSearchFilters.start_from_date), // cut time
    start_to_date: formatSearchFilterDate(loadSearchFilters.start_to_date), // cut time
    scac_search_list: loadSearchFilters.scacs,
    category_list: loadSearchFilters.category_list
      ? loadSearchFilters.category_list.map((value) => value.type)
      : [],
    subcategory_list: loadSearchFilters.category_list
      ? loadSearchFilters.category_list.flatMap((value) => value.sub_types)
      : [],
    alert_status: loadSearchFilters.alert_statuses,
    critical_departure_start_time:
      loadSearchFilters.critical_departure_start_time,
    critical_departure_end_time: loadSearchFilters.critical_departure_end_time,
    arrival_start_time: loadSearchFilters.arrival_start_time,
    arrival_end_time: loadSearchFilters.arrival_end_time,
    trailer_number_list: loadSearchFilters.trailer_numbers,
    criteria_id: 37,
    email: user.email,
    report_type: type,
  }
}

export default {
  getLoads,
  getCSVReport,
  updateDepartureLog,
  getCriteria,
  getLocation,
}
