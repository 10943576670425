// double check sorting capabilities for each field

export const defaultColumnDef = {
  sortable: false,
  width: 170,
}

export const columnDefs = [
  {
    headerName: 'Departure Status',
    field: 'departure_log_status',
    sortBy: 'departure_log_status',
    width: 220,
    isRowHeader: true,
    sortable: true,
    defaultSort: 'dsc',
  },
  {
    headerName: 'Origin',
    field: 'origin',
    sortBy: 'origin_id',
    width: 280,
    sortable: true,
  },
  {
    headerName: 'Destination',
    field: 'destination',
    sortBy: 'destination_id',
    width: 280,
    sortable: true,
  },
  {
    headerName: 'Unload Type',
    field: 'unload_type',
    sortBy: null,
    width: 120,
    sortable: false,
  },
  {
    headerName: 'Trailer #',
    field: 'trailer_number',
    sortBy: null,
    width: 120,
    sortable: false,
  },
  {
    headerName: 'Cut Time',
    field: 'cut_time',
    sortBy: 'load_start_date',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'SCAC',
    field: 'current_scac',
    sortBy: 'current_scac',
    width: 100,
    sortable: true,
  },
  {
    headerName: 'Pro #',
    field: 'pro_number',
    sortBy: 'pro_number',
    width: 120,
    sortable: true,
  },
  {
    headerName: 'Trip ID',
    field: 'trip_id',
    sortBy: 'trip_id',
    width: 120,
    sortable: true,
  },
  {
    headerName: 'Load ID',
    field: 'load_id',
    sortBy: 'load_id',
    width: 120,
    sortable: true,
  },
  {
    headerName: 'Critical Departure Time',
    field: 'critical_departure_time',
    sortBy: 'critical_departure_time',
    width: 220,
    sortable: true,
  },
  {
    headerName: 'Arrival Time',
    field: 'arrival_time',
    sortBy: 'arrival_time',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'Delivery Sequence',
    field: 'delivery_sequence',
    sortBy: null,
    width: 150,
    sortable: false,
  },
  {
    headerName: 'Schedule ID',
    field: 'schedule_id',
    sortBy: 'schedule_id',
    width: 150,
    sortable: true,
  },
  {
    headerName: 'Comments',
    field: 'comments',
    sortBy: 'comments',
    width: 350,
    sortable: false,
  },
]
