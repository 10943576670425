import { NavLink, useLocation } from 'react-router-dom'
import { Heading, SideNav, TargetLogo } from '@enterprise-ui/canvas-ui-react'

export const SideNavigation = ({ isVisible, onRequestClose }) => {
  const location = useLocation()

  return (
    <SideNav
      data-testid="sideNav"
      suppressAutoOpen
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      hasOverlay
    >
      <SideNav.Header
        data-testid="sideNavLogoHeader"
        as={NavLink}
        to="/"
        onClick={onRequestClose}
      >
        <TargetLogo size="expanded" className="hc-mr-dense" />
        <Heading size={1} className="hc-fs-md">
          Departure Log
        </Heading>
      </SideNav.Header>
      <SideNav.Navigation data-testid="sideNavMainNavArea">
        <SideNav.NavigationItem
          as={NavLink}
          to="/"
          className={location.pathname === '/' ? 'isSelected' : ''}
          onClick={onRequestClose}
        >
          Departure Log
        </SideNav.NavigationItem>
      </SideNav.Navigation>
    </SideNav>
  )
}
