import { Routes, Route, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'

import { NotFound } from '@dlm/common'

import { ErrorContent } from './ErrorContent'
import DepartureLog from '../DepartureLog/DepartureLog'

export const MainRouter = () => {
  const location = useLocation()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        {/* Application routes*/}
        <Route path="/" element={<DepartureLog />} />

        {/* 404*/}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </ErrorBoundary>
  )
}
